import { useState, useEffect } from 'react'
import api from '../api/api.js'
import handleSessionExpired from '../utils/handleSessionExpired.js'
import validateRoles from '../utils/validateRoles.js'

function getContactValidity (formattedContact) {
  const rawContact = formattedContact.contact || {}
  const requiredValues = ['SALUTATION', 'FIRSTNAME', 'LASTNAME', 'EMAIL']
  const hasAllRequiredValues = requiredValues.every(key => !!rawContact[key])
  const { hasAtLeastOneRole, rolesWithErrors } = validateRoles(formattedContact.groupedRoles)
  if (!hasAllRequiredValues || !hasAtLeastOneRole || rolesWithErrors.size) return false
  return true
}

// group roles of the same type together
function formatContact (contact) {
  const roles = contact.acr || []
  const groupedRoles = new Map()
  roles.forEach(role => {
    const currentRole = groupedRoles.get(role.ROLE__C) || {
      role,
      segments: []
    }
    groupedRoles.set(role.ROLE__C, currentRole)
    if (role.SEGMENT__C) {
      currentRole.segments.push({
        _id: role._id,
        SEGMENT__C: role.SEGMENT__C,
        SEGMENTCODE__C: role.SEGMENTCODE__C,
        YEAR1PRESENT__C: role.YEAR1PRESENT__C,
        YEAR2PRESENT__C: role.YEAR2PRESENT__C,
        YEAR3PRESENT__C: role.YEAR3PRESENT__C,
        YEAR4PRESENT__C: role.YEAR4PRESENT__C,
        YEAR5PRESENT__C: role.YEAR5PRESENT__C,
        YEAR6PRESENT__C: role.YEAR6PRESENT__C
      })
    }
  })
  return {
    contact,
    groupedRoles,
    isValid: getContactValidity({
      contact,
      groupedRoles
    })
  }
}

function sortContacts (contacts) {
  return [...contacts].sort((contactA, contactB) => {
    const contactAFirstName = contactA.contact.FIRSTNAME.toLowerCase()
    const contactBFirstName = contactB.contact.FIRSTNAME.toLowerCase()
    const contactALastName = contactA.contact.LASTNAME.toLowerCase()
    const contactBLastName = contactB.contact.LASTNAME.toLowerCase()
    if (contactALastName > contactBLastName) {
      return 1
    }
    if (contactALastName < contactBLastName) {
      return -1
    }
    if (contactAFirstName > contactBFirstName) {
      return 1
    }
    if (contactAFirstName < contactBFirstName) {
      return -1
    }
    return 0
  })
}

function formatContacts (contacts) {
  return contacts.map(formatContact)
}

const pageSize = 50

function useContacts (initialContacts) {
  const [contacts, setContacts] = useState(initialContacts)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [forceReloadValue, forceReload] = useState(false)

  function reloadAllContacts () {
    forceReload(!forceReloadValue)
  }

  useEffect(() => {
    let isUnmounting = false

    async function getContacts () {
      setLoading(true)
      setError(null)
      try {
        const contacts = await getAllContacts()
        if (isUnmounting) return
        const formattedAndSortedContacts = sortContacts(formatContacts(contacts))
        return setContacts(formattedAndSortedContacts)
      } catch (error) {
        if (error.response && error.response.status === 403) {
          return handleSessionExpired()
        }
        if (isUnmounting) return
        return setError(error)
      } finally {
        if (!isUnmounting) setLoading(false)
      }
    }
    getContacts()

    return () => { isUnmounting = true }
  }, [forceReloadValue])

  async function reloadContact (contactObjectId) {
    const response = await api.contacts.getContact(contactObjectId)
    const newContact = formatContact(response.contact)
    const newContacts = contacts.filter((contact) => {
      return (contact.contact._id !== contactObjectId)
    })
    newContacts.push(newContact)
    setContacts(sortContacts(newContacts))
  }

  function removeContact (contactObjectId) {
    const newContacts = contacts.filter((contact) => {
      return (contact.contact._id !== contactObjectId)
    })
    setContacts(newContacts)
  }

  return [error, loading, contacts, reloadContact, removeContact, reloadAllContacts]
}

async function getAllContacts () {
  let allContacts = []
  let currentPage = 0
  let notDone = true
  while (notDone) {
    const contacts = await getNextPage({ page: currentPage, pageSize })
    if (contacts.success) {
      allContacts = [...allContacts, ...contacts.data]
      if (contacts.total < pageSize) {
        notDone = false
      } else {
        currentPage++
      }
    } else {
      throw new Error(contacts)
    }
  }
  return allContacts
}

function getNextPage ({ page, pageSize }) {
  return api.contacts.getContacts({ page, pageSize })
}

export default useContacts
