import { useState, useEffect, useRef } from 'react'
import Header from '../../components/Header/Header.js'
import Footer from '../../components/Footer/Footer.js'
import './Home.scss'
import Button from '../../components/Button/Button.js'
import Icon from '../../components/Icon/Icon.js'
import useAccount from '../../hooks/useAccount.js'
import useContacts from '../../hooks/useContacts.js'
import useHandleErrors from '../../hooks/useHandleErrors.js'
import EditAccountForm from './EditAccountForm/EditAccountForm.js'
import Modal from '../../components/Modal/Modal.js'
import Spinner from '../../components/Spinner/Spinner.js'
import Contacts from './Contacts/Contacts.js'
import NumberInput from '../../components/NumberInput/NumberInput.js'
import CreateContactForm from './CreateContactForm/CreateContactForm.js'
import SubmitModal from './SubmitModal/SubmitModal.js'
import Loading from './Loading.js'

const Home = () => {
  const [accountError, accountLoading, account, reloadAccount] = useAccount({})
  const [contactsError, contactsLoading, formattedContacts, reloadContact, removeContact] = useContacts([])
  const [showEditEmailForm, setShowEditEmailForm] = useState(false)
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [totalDiaries, setTotalDiaries] = useState(0)
  const [showCreateContactForm, setShowCreateContactForm] = useState(false)
  const [editModalContact, setEditModalContact] = useState(null)
  const printRef = useRef(null)

  const isLocked = account && account.isLocked

  useHandleErrors([accountError, contactsError])

  useEffect(() => {
    if (account && account.totalDiaries) {
      setTotalDiaries(account.totalDiaries)
    }
  }, [account])

  useEffect(() => {
    setTotalDiaries(formattedContacts.length)
  }, [formattedContacts])

  function handleEditEmailClick () {
    setShowEditEmailForm(true)
  }

  function handleModalClose () {
    setShowEditEmailForm(false)
  }

  function handleCreateContactModalOpen () {
    setShowCreateContactForm(true)
  }

  function handleCreateContactModalClose () {
    setEditModalContact(null)
    setShowCreateContactForm(false)
  }

  function showEditModal (contact) {
    return () => {
      setEditModalContact(contact)
      setShowCreateContactForm(true)
    }
  }

  function handleSubmitModalOpen () {
    setShowSubmitModal(true)
  }

  function handleSubmitModalClose () {
    setShowSubmitModal(false)
  }

  function getStreet () {
    return [account.STREET__C, account.NUMBER__C].join(' ')
  }

  function getCity () {
    return [account.POSTALCODE__C, account.CITY__C].join(' ')
  }

  function handleDiariesChange (name) {
    return value => {
      setTotalDiaries(value)
    }
  }

  function handlePrintClick () {
    if (printRef.current) printRef.current.click()
  }

  const contactsCount = !contactsLoading && !contactsError && formattedContacts.length
  const surnameLetters = new Set(formattedContacts.map(({ contact }) => ((contact.LASTNAME || '')[0] || '').toLowerCase()).filter(a => a))
  const allContactsAreValid = formattedContacts.every(({ isValid }) => isValid)

  if (accountLoading) {
    return (
      <Loading/>
    )
  }

  return (
    <div className="Home">
      <Header />
      <div className="center">
        <h2>Mijn schoolteamgegevens</h2>
        {
          isLocked
            ? <h5>De gegevens van jouw school zijn al ingediend.</h5>
            : <h5>Pas in de lijst hieronder de gegevens van je schoolteam aan en ontvang een schoolagenda 2024-2025 voor het hele schoolteam.</h5>
        }
        <div className="row-top">
          <div className="block school">
            <div className="title"><h3>School</h3>{ accountLoading && <Spinner width={20} height={20} /> }</div>
            <hr />
            <div className="details">
              <div className="details-container">
                <p className="caption">Klantnummer</p>
                <p className="value">{account.BUSINESSPARTNERID__C || ''}</p>
              </div>
              <div className="details-container">
                <p className="caption">Naam</p>
                <p className="value">{account.NAME || ''}</p>
              </div>
              <div className="details-container">
                <p className="caption">Adres</p>
                <p className="value">{getStreet()}</p>
              </div>
              <div className="details-container">
                <p className="caption">Postcode - gemeente</p>
                <p className="value">{getCity()}</p>
              </div>
              <div className="details-container">
                <p className="caption with-button">E-mail { !isLocked && <button onClick={handleEditEmailClick}><Icon name="pencil" width={14} height={14} />Aanpassen</button> }</p>
                <p className="value">{account.EMAIL__C || ''}</p>
              </div>
            </div>
          </div>
          <div className="block print">
            <a id="printA" href="/print" target="_blank" ref={printRef}>Lijst printen</a>
            <Button onClick={handlePrintClick}><Icon name="print" width={18} height={18} /><span>Lijst printen</span></Button>
            <div className="text">
              <div className="acrobat-logo">
                <picture>
                  <img src="/images/acrobat.png" alt="acrobat reader logo" />
                </picture>
              </div>
              <p><span>
                Wil je de lijst (pdf) printen om hem voor te leggen aan de collega’s? Druk dan op ‘Lijst printen’. Heb je geen Acrobat Reader, dan kan je die hier downloaden.
                &nbsp;
                <a target="_blank" rel="noopener noreferrer" href="https://get2.adobe.com/nl/reader/">Download<Icon name="external" width={15} height={15} /></a>
              </span></p>
            </div>
          </div>
        </div>
        <div className="block team">
          <div className="head">
            <div className="title"><h3 id="team">Team</h3>{ contactsLoading && <Spinner width={20} height={20} /> }{ !!contactsCount && <p>{`Totaal ${contactsCount} personen`}</p> }</div>
            { !isLocked && formattedContacts.length <= 20 && <Button onClick={handleCreateContactModalOpen}><Icon name="add" />Persoon toevoegen</Button> }
          </div>
          <hr />
          <div className="team-list">
            { formattedContacts.length
              ? (
                <Contacts
                  formattedContacts={formattedContacts}
                  surnameLetters={surnameLetters}
                  removeContact={removeContact}
                  showEditModal={showEditModal}
                  isLocked={isLocked}
                  handleCreateContactModalOpen={handleCreateContactModalOpen}
                />
                )
              : <div className="empty">
                <p>Voeg een persoon toe aan jouw team!</p>
              </div>
            }
            { !isLocked && !!formattedContacts.length && <div className="order">
              <NumberInput value={totalDiaries} onChange={handleDiariesChange('totalDiaries')} min={0} max={contactsCount || 0} />
              <div className="text">
                <p>Gratis schoolagenda’s bestellen</p>
                <p>Door de schoolteamgegevens in te dienen, reserveer je gratis schoolagenda’s voor iedereen op de lijst. Heb je er minder nodig, duid dan aan hoeveel je er wenst te reserveren. De agenda’s worden uitgestuurd vanaf maart 2024.</p>
              </div>
              <Icon name="agenda" fill="var(--yellow)" width={51} height={51} />
            </div> }
            { !isLocked && !!formattedContacts.length && <div className="Home-submit-button"><Button disabled={!allContactsAreValid} onClick={handleSubmitModalOpen}>Gegevens indienen</Button></div> }
          </div>
        </div>
      </div>
      <Footer />
      <Modal show={showEditEmailForm} onClose={handleModalClose}>
        <EditAccountForm email={account.EMAIL__C} closeModal={handleModalClose} reloadAccount={reloadAccount} label="E-mail" updateField="EMAIL__C" />
      </Modal>
      <Modal show={showCreateContactForm} onClose={handleCreateContactModalClose} name="create">
        <CreateContactForm
          closeModal={handleCreateContactModalClose}
          reloadContact={reloadContact}
          formattedContacts={formattedContacts}
          formattedContact={editModalContact}
        />
      </Modal>
      <Modal show={showSubmitModal} onClose={handleSubmitModalClose}>
        <SubmitModal closeModal={handleSubmitModalClose} totalDiaries={totalDiaries} />
      </Modal>
    </div>
  )
}

export default Home
